<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content :pageTitle="'List Borrower Middleware'" />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari kata kunci disini"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
      </div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Nama Lengkap
              </th>
              <th class="text-left">
                Tipe
              </th>
              <th class="text-left">
                Nomor ID
              </th>
              <th class="text-left">
                Bank
              </th>
              <th class="text-left">
                Email
              </th>
              <th class="text-left">
                Tanggal Lahir
              </th>
              <th class="text-left">
                Status Verifikasi
              </th>
              <th class="text-left">
                Status Approval
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(borrower, i) in borrowers"
              :key="i"
              class="cursor-pointer"
              @click="toDetail(borrower.idNumber)"
            >
              <td>{{ borrower.fullName }}</td>
              <td>{{ borrower.borrowerType }}</td>
              <td>{{ borrower.idNumber }}</td>
              <td>{{ borrower.borrowerBank }}</td>
              <td>{{ borrower.email }}</td>
              <td>{{ borrower.birthDateFormatted }}</td>
              <td
                v-if="borrower.isVerified"
                class="green--text font-weight-medium"
              >
                Sudah Terverifikasi
              </td>
              <td v-else class="red--text font-weight-medium">
                Belum Terverifikasi
              </td>
              <td
                v-if="borrower.status === 'APPROVED'"
                class="green--text font-weight-bold"
              >
                APPROVED
              </td>
              <td
                v-else-if="borrower.status === 'REJECTED'"
                class="red--text font-weight-bold"
              >
                REJECTED
              </td>
              <td v-else class="yellow--text text--darken-3 font-weight-bold">
                PENDING
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-col cols="6" class="mx-auto">
        <v-pagination
          v-model="borrowersPagination.page"
          class="my-4"
          :length="borrowersPagination.totalPages"
          :total-visible="7"
          @input="toPage"
        ></v-pagination>
      </v-col>
    </v-card>
    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import converters from "../../helpers/converters.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      loading: true,
      isAllLoaded: false,
      borrowers: [],
      borrowersPagination: {},
    };
  },

  methods: {
    toDetail(item) {
      this.$router.push("/input-borrower-mw/" + item);
    },
    async getPayment() {
      this.loading = true;

      await store.dispatch("starlord/fetchAllBorrowerMiddleware").then((r) => {
        if (r) {
          this.borrowers = store.state.starlord.allBorrowersMiddleware.items;
          this.borrowersPagination =
            store.state.starlord.allBorrowersMiddleware.pagination;
          this.borrowers.forEach((a) => {
            a.borrowerBank = converters.bankNameMiddleware(a.borrowerBankId);
            a.birthDateFormatted = converters.mediumDate(a.birthDate);
          });
          this.loading = false;
        }
      });
    },
    async toPage(page) {
      this.loading = true;

      await store
        .dispatch("starlord/fetchAllBorrowerMiddleware", page)
        .then((r) => {
          if (r) {
            this.borrowers = store.state.starlord.allBorrowersMiddleware.items;
            this.borrowersPagination =
              store.state.starlord.allBorrowersMiddleware.pagination;
            this.borrowers.forEach((a) => {
              a.borrowerBank = converters.bankNameMiddleware(a.borrowerBankId);
              a.birthDateFormatted = converters.mediumDate(a.birthDate);
            });
            this.loading = false;
          }
        });
    },
  },

  mounted() {
    this.getPayment();
  },
};
</script>
